import React from "react"
import styled from "styled-components"

import Header from "../components/Header"
import Footer from "../components/Footer"
import SEO from "../components/SEO"

import GlobalStyles from "../components/styles/GlobalStyles"
import { Wrapper } from "../components/styles/Container"

const LayoutStyles = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: auto 1fr auto;
  min-height: 100vh;
`

const Layout = ({ children }) => (
  <div>
    <GlobalStyles />
    <SEO />
    <LayoutStyles>
      <Header />
      <main>
        <Wrapper>{children}</Wrapper>
      </main>
      <Footer />
    </LayoutStyles>
  </div>
)

export default Layout
