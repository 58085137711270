import React from "react"
import styled from "styled-components"
import { Link } from "gatsby"
import { useMediaQuery } from "react-responsive"

import { Wrapper } from "../components/styles/Container"
import "./styles/klaus-gridski.css"

import Logo from "../img/icons/logo.svg"
import Connect from "../img/icons/cn2_studio.svg"
import Facebook from "../img/icons/002-facebook.svg"
import Fiverr from "../img/icons/001-fiverr.svg"
import Mail from "../img/icons/paper-plane.svg"
import Insta from "../img/icons/instagram.svg"

const FooterStyles = styled.footer`
  background-color: var(--footer);
  padding: 7rem 0;
  a {
    text-transform: lowercase;
  }
  font-family: var(--headline);
  font-weight: 600;
`

const Policy = styled.div`
  border-top: 1px solid var(--mint);
  border-bottom: 1px solid var(--mint);
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 2rem 0;
  width: 150px;
  margin: 3rem 0;
`

const Icon = styled.img`
  width: 20px;
  margin: 0 0.8rem;
`

const IconSection = styled.div`
  border-bottom: 1px solid var(--mint);
  padding-bottom: 2rem;
  margin-bottom: 2rem;
  width: 150px;
`

const FooterContent = styled.div`
  min-height: 250px;
  font-size: 1.5rem;
`

const LogoStyle = styled.img`
  width: 14rem;
`

const ConnectStyle = styled.img`
  height: 7rem;
  animation: rotation 25s infinite linear;
  @keyframes rotation {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(359deg);
    }
  }
`

const Footer = () => {
  // Mediaqueries
  const isDesktop = useMediaQuery({
    query: "(min-device-width: 75em)",
  })
  const isTablet = useMediaQuery({
    query: "(min-device-width: 48em) and (max-device-width: 75em)",
  })
  const isMobile = useMediaQuery({
    query: "(max-device-width: 48em)",
  })
  return (
    <FooterStyles>
      <Wrapper className="flex h-center">
        {isMobile || isTablet ? (
          <FooterContent className="flex vertical v-center around">
            <LogoStyle src={Logo} />
            <Policy>
              <Link to="/imprint">Imprint</Link>
              <Link to="/datenschutz">Privacy policy</Link>
            </Policy>
            <IconSection>
              <a
                target="_blank"
                title="send mail"
                rel="noreferrer"
                href="mailto:info@qualix-audio.com"
              >
                <Icon src={Mail} />
              </a>

              <a
                target="_blank"
                rel="noreferrer"
                title="qualix on facebook"
                href="https://www.facebook.com/QualixAudio"
              >
                <Icon src={Facebook} />
              </a>
              <a
                target="_blank"
                rel="noreferrer"
                title="qualix on instagram"
                href="https://www.instagram.com/qualix_audio/"
              >
                <Icon src={Insta} />
              </a>
              <a
                target="_blank"
                rel="noreferrer"
                title="qualix on fiverr"
                href="https://www.fiverr.com/dolorian"
              >
                <Icon src={Fiverr} />
              </a>
            </IconSection>
            <a href="https://connect2.gmbh/" target="_blank" rel="noreferrer">
              <ConnectStyle src={Connect} />
            </a>
          </FooterContent>
        ) : (
          isDesktop && (
            <FooterContent className="flex vertical v-center around">
              <LogoStyle src={Logo} />
              <Policy>
                <Link to="/imprint">Imprint</Link>
                <Link to="/datenschutz">Privacy policy</Link>
              </Policy>
              <a href="https://connect2.gmbh/" target="_blank" rel="noreferrer">
                <ConnectStyle src={Connect} />
              </a>
            </FooterContent>
          )
        )}
      </Wrapper>
    </FooterStyles>
  )
}

export default Footer
